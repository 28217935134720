import GetImage from 'components/functional/getImage'
import {StaticImage} from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import {media} from 'utils/Media'
import ScrollIcon from 'svgs/scroll.svg'

const PageHeaderStyles = styled.header`
  width: 100%;
  margin-top: 5.5rem;
  @media ${media.md} {
    margin-top: 0;
  }

  section {
    position: relative;
    z-index: 2;

    svg {
      overflow: visible;
      position: absolute;
      bottom: 2rem;
      left: calc(0% + 45%);
      @media ${media.md} {
        left: calc(0% + 49%);
      }
      fill: var(--white);
      width: 30px;
      height: 30px;

      #scroll-1 {
        animation: fade-in-top 2.5s 0.25s cubic-bezier(0.39, 0.575, 0.565, 1) infinite;
      }

      #scroll-2 {
        animation: fade-in-top 2.5s cubic-bezier(0.39, 0.575, 0.565, 1) infinite;
      }
    }
  }

  .mobile {
    position: relative;

    .before {
      position: absolute;
      left: 0;
      top: 0;
      width: 7rem;
      height: 100%;
      z-index: 100;
      background: linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, transparent 100%);
      content: '';
    }

    .after {
      position: absolute;
      right: 0;
      top: 0;
      width: 7rem;
      height: 100%;
      z-index: 100;
      background: linear-gradient(270deg,
      rgba(0, 0, 0, 1) 0%,
      transparent 100%);
      content: '';
    }

    @media ${media.md} {
      display: none;
    }
  }

  .desktop {
    display: none;
    @media ${media.md} {
      display: block;
      margin: var(--auto);
      text-align: center;
      position: relative;
      width: 100%;
      max-width: 1440px;
      margin: var(--auto);

      .before {
        position: absolute;
        left: 0;
        top: 0;
        width: 30rem;
        height: 100%;
        z-index: 100;
        background: linear-gradient(90deg,
        rgba(0, 0, 0, 1) 0%,
        transparent 100%);
        content: '';
      }

      .after {
        position: absolute;
        right: 0;
        top: 0;
        width: 30rem;
        height: 100%;
        z-index: 100;
        background: linear-gradient(270deg,
        rgba(0, 0, 0, 1) 0%,
        transparent 100%);
        content: '';
      }
    }
    @media ${media.lg} {
      .gatsby-image-wrapper {
        height: auto;
        width: 100%;
        max-height: 580px;
      }

      .before,
      .after {
        width: 40rem;
      }
    }
  }

  @media ${media.xl} {
    .gatsby-image-wrapper {
      width: 100%;
    }

    .before,
    .after {
      width: 40rem;
    }
  }
`
const PageHeader = ({mobileHeader, desktopHeader}) => (
    <PageHeaderStyles>
        <section>
            <div className="mobile">
                <div className="before"/>
                <GetImage
                    data={mobileHeader}
                    alt="The Mousetrap Official Site - The world’s longest running play"
                />
                <div className="after"/>
            </div>
            <div className="desktop">
                <div className="before"/>
                <GetImage
                    data={desktopHeader}
                    alt="The Mousetrap Official Site - The world’s longest running play"
                />
                <div className="after"/>
            </div>
            <ScrollIcon/>
        </section>
    </PageHeaderStyles>
)

export default PageHeader
